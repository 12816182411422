import React from "react"

const Section = ({ children, className }) => {
  return (
    <div className={`wrapper ${className}`}>
      <div className="container">{children}</div>
    </div>
  )
}

export default Section
