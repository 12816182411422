import React from "react"

//component
import Section from "../components/Section"
import Branding from "../components/Branding"
import PageInfo from "../components/Page/Info"

const Bottom = ({ langCurrent }) => {
  return (
    <div className="bottom">
      <Section className="branding">
        <Branding title="yes" langCurrent={langCurrent} />
      </Section>
      <Section className="infoMenu">
        <PageInfo langCurrent={langCurrent} />
      </Section>
    </div>
  )
}

export default Bottom
