export default [
  {
    locale: "fr",
    title: "Accueil",
    path: "/",
  },
  {
    locale: "fr",
    title: "Commerçants",
    path: "/commercants",
  },

  {
    locale: "fr",
    title: "Actus",
    path: "/actus",
  },

  {
    locale: "fr",
    title: "Contact",
    path: "/contact",
  },
]
