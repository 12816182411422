import React from "react"
import { Link } from "gatsby"

const LangueSelect = ({ langSelect, langCurrent }) => {
  return (
    <ul className="langSelect">
      {langSelect.map((item, index) => {
        return (
          <li key={index}>
            {langCurrent === item.locale ? (
              <span>{item.locale}</span>
            ) : (
              <Link to={`/${item.locale}${item.path}`}>{item.locale}</Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default LangueSelect
