import React from "react"

//components
import Section from "../components/Section"
import Social from "../components/Social"
import Phone from "../components/Phone"
import Mail from "../components/Mail"

const Top = ({ langCurrent }) => {
  return (
    <Section className="top">
      <div className="block">
        <Phone />
        <Mail langCurrent={langCurrent} />
      </div>
      <Social langCurrent={langCurrent} />
    </Section>
  )
}

export default Top
