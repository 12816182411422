import React from "react"

//components
import Top from "../components/Top"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Bottom from "../components/Bottom"

//css
import "../css/global.css"
import "../css/gird.css"
import "../css/top.css"
import "../css/branding.css"
import "../css/menu.css"
import "../css/search.css"
import "../css/lastNews.css"

const Layout = ({ langSelect, langCurrent, children }) => {
  return (
    <div>
      <Top langCurrent={langCurrent} />
      <Header langSelect={langSelect} langCurrent={langCurrent} />
      {children}
      <Footer langCurrent={langCurrent} />
      <Bottom langCurrent={langCurrent} />
    </div>
  )
}

export default Layout
