import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const getData = graphql`
  {
    infoFr: allContentfulInfo(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        socialText
      }
    }
    infoNl: allContentfulInfo(filter: { node_locale: { eq: "nl" } }) {
      nodes {
        socialText
      }
    }
    icon: allContentfulSocial(
      filter: { active: { eq: true }, node_locale: { eq: "fr" } }
    ) {
      nodes {
        link
        icon {
          svg {
            content
          }
        }
      }
    }
  }
`

const Social = ({ langCurrent }) => {
  const data = useStaticQuery(getData)
  return (
    <div className="social">
      {langCurrent === "fr" ? (
        <span>{data.infoFr.nodes[0].socialText}</span>
      ) : (
        <span>{data.infoNl.nodes[0].socialText}</span>
      )}
      <ul>
        {data.icon.nodes.map((item, index) => {
          return (
            <li key={index}>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                <div
                  className="icon"
                  dangerouslySetInnerHTML={{ __html: item.icon.svg.content }}
                />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Social
