import React from "react"

// components
import Section from "../components/Section"
import Social from "../components/Social"
import MainMenuFr from "./Menu/MainMenuFr"
import MainMenuNl from "./Menu/MainMenuNl"

const Footer = ({ langCurrent }) => {
  return (
    <div className="footer">
      <Section className="social">
        <Social langCurrent={langCurrent} />
      </Section>
      <Section className="menu">
        {langCurrent === "fr" ? <MainMenuFr /> : <MainMenuNl />}
      </Section>
    </div>
  )
}

export default Footer
