import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import IconPhone from "../images/contact/phone.svg"

const getData = graphql`
  {
    phone: allContentfulInfo(limit: 1) {
      nodes {
        phoneTitle
        phoneLink
      }
    }
  }
`

const Phone = () => {
  const data = useStaticQuery(getData)
  const phone = data.phone.nodes[0]
  return (
    <a href={phone.phoneLink} className="phone">
      <IconPhone className="icon" />
      {phone.phoneTitle}
    </a>
  )
}

export default Phone
