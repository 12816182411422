import React from "react"
import { Link } from "gatsby"

//constants
import MainMenu from "../../constants/MainMenuFr"

const MainMenuFr = () => {
  const home = "/"

  return (
    <ul className="mainMenu">
      {MainMenu.map((item, index) => {
        return (
          <li key={index}>
            <Link
              to={`/${item.locale}${item.path}`}
              activeClassName="is-active"
              partiallyActive={item.path === home ? false : true}
            >
              {item.title}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default MainMenuFr
