export default [
  {
    locale: "nl",
    title: "Home",
    path: "/",
  },
  {
    locale: "nl",
    title: "Handelaren",
    path: "/handelaren",
  },
  {
    locale: "nl",
    title: "News",
    path: "/news",
  },
  {
    locale: "nl",
    title: "Contact",
    path: "/contact",
  },
]
