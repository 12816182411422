import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import useAutocomplete from "@material-ui/lab/useAutocomplete"

const getData = graphql`
  {
    fr: allContentfulCommercant(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        title
        subtitle
        slug
        type
      }
    }
    nl: allContentfulCommercant(filter: { node_locale: { eq: "nl" } }) {
      nodes {
        title
        subtitle
        slug
        type
      }
    }
  }
`

const Search = ({ langCurrent }) => {
  const data = useStaticQuery(getData)
  const commercants = langCurrent === "fr" ? data.fr.nodes : data.nl.nodes

  const langPath = langCurrent === "fr" ? "commercants" : "handelaren"

  const {
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: commercants,
    getOptionLabel: option =>
      option.type + " " + option.title + " " + option.subtitle,
  })
  return (
    <div className="searchBox">
      <div>
        <input
          {...getInputProps()}
          placeholder={
            langCurrent === "fr"
              ? "Rechercher un commerçant"
              : "Zoek een handelaar"
          }
        />
      </div>
      {groupedOptions.length > 0 ? (
        <ul {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <Link to={`/${langCurrent}/${langPath}/${option.slug}`}>
                <strong>{option.type} : </strong> {option.title} -{" "}
                <span>{option.subtitle}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default Search
