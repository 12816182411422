import React, { useState } from "react"

//components
import LangSelect from "../components/LangueSelect"
import Section from "../components/Section"
import Branding from "../components/Branding"
import Partner from "../components/Partner"
import Search from "../components/Search"

import MainMenuFr from "./Menu/MainMenuFr"
import MainMenuNl from "./Menu/MainMenuNl"
import IconSearch from "../images/contact/search.svg"

const Header = ({ langSelect, langCurrent }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleSearch = () => {
    setIsOpen(isOpen => !isOpen)
  }
  return (
    <>
      <Section className="branding">
        <Partner />
        <Branding langCurrent={langCurrent} />
        <LangSelect langSelect={langSelect} langCurrent={langCurrent} />
      </Section>
      <Section className="menu">
        {langCurrent === "fr" ? <MainMenuFr /> : <MainMenuNl />}
        <div className="icon-search">
          <IconSearch className="icon" onClick={toggleSearch} />
        </div>
      </Section>
      <Section className={`search ${isOpen ? "active" : ""}`}>
        <Search langCurrent={langCurrent} />
      </Section>
    </>
  )
}

export default Header
