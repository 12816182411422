import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const getData = graphql`
  {
    pageFr: allContentfulPage(
      filter: {
        contentful_id: { eq: "6ZT0Sll7MKscRepkDGL7G6" }
        node_locale: { eq: "fr" }
      }
    ) {
      nodes {
        title
        contentful_id
        slug
      }
    }
    pageNl: allContentfulPage(
      filter: {
        contentful_id: { eq: "6ZT0Sll7MKscRepkDGL7G6" }
        node_locale: { eq: "nl" }
      }
    ) {
      nodes {
        title
        contentful_id
        slug
      }
    }
  }
`

const Info = ({ langCurrent }) => {
  const data = useStaticQuery(getData)
  const info = langCurrent === "fr" ? data.pageFr.nodes : data.pageNl.nodes
  return (
    <ul>
      {info.map((item, index) => {
        return (
          <li key={index}>
            <Link to={`/${langCurrent}/${item.slug}`}>{item.title}</Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Info
