import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const getData = graphql`
  {
    info: allContentfulInfo {
      nodes {
        logo {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    titleFr: allContentfulInfo(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        title
      }
    }
    titleNl: allContentfulInfo(filter: { node_locale: { eq: "nl" } }) {
      nodes {
        title
      }
    }
  }
`

const Branding = ({ title, langCurrent }) => {
  const data = useStaticQuery(getData)
  const info = data.info.nodes[0]
  const titleFr = data.titleFr.nodes[0]
  const titleNl = data.titleNl.nodes[0]

  return (
    <Link to="/" className="site">
      <Img fluid={info.logo.fluid} />

      {!title ? "" : langCurrent === "fr" ? titleFr.title : titleNl.title}
    </Link>
  )
}

export default Branding
