import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getData = graphql`
  {
    partner: allContentfulPartner(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        logo {
          fixed(width: 25) {
            ...GatsbyContentfulFixed_tracedSVG
          }
        }
        link
        title
      }
    }
  }
`

const Partner = () => {
  const data = useStaticQuery(getData)
  return (
    <ul className="partner">
      {data.partner.nodes.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              <Img fixed={item.logo.fixed} alt={item.title} className="logo" />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Partner
