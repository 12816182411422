import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import IconMail from "../images/contact/mail.svg"

const getData = graphql`
  {
    mailFr: allContentfulInfo(filter: { node_locale: { eq: "fr" } }) {
      nodes {
        contactText
      }
    }
    mailNl: allContentfulInfo(filter: { node_locale: { eq: "nl" } }) {
      nodes {
        contactText
      }
    }
  }
`

const Mail = ({ langCurrent }) => {
  const data = useStaticQuery(getData)
  const mailFr = data.mailFr.nodes[0]
  const mailNl = data.mailNl.nodes[0]
  return (
    <Link
      to={langCurrent === "fr" ? "/fr/contact" : "/nl/contact"}
      className="mail"
    >
      <IconMail className="icon" />
      {langCurrent === "fr" ? (
        <span>{mailFr.contactText}</span>
      ) : (
        <span>{mailNl.contactText}</span>
      )}
    </Link>
  )
}

export default Mail
